@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

:root{
  font-family: 'Titillium Web', sans-serif;
}

.container {
  padding: 0 2rem;
}



.main {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title{
  font-size: 80px;
}

.buttonContainer{
  padding-top: 75px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.buttonContainer > div{
  padding-right: 5px;
  padding-left: 5px;
}


.Button{
  color: black;
  text-transform: none;
  padding: 40px;
  width: 120px;
  height: 35px;
}
