html{
  margin: 0;
}

body{
  margin: 0;
}


:root{
  --row: 0;
  --m: min(calc(var(--width) / 960), calc(var(--height) / 540));   /* margin */
  --s: min(calc(var(--width) / 16 - var(--width) / 512), calc(var(--height) / 11));  /* size */
  
  --width: 60vw;
  --height: 80vh;
}

@media only screen and (orientation: portrait){
  :root{
    --width: 100vw;
    --height: 50vh
  }

}

.leave{
  align-self: flex-start;
  justify-self: end;
  position: absolute;
  margin-top: 5px;
}

.page{
  width: 100vw;
  height: 100vh;
  display: grid;
  flex-direction: column;
  justify-content: center;
  flex-wrap: initial;
}

.main {
    display: flex; /* we will talk about this later ... */
    position: relative;
    align-self: center;
}
  
  
.container {
    font-size: 0; /* disable white space between inline block element */
    white-space: nowrap;
    overflow: hidden;
    min-width: var(--width);
    height: var(--height);
    background: black;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-top: solid 2px red;
    border-bottom: solid 2px red;
    border-left: solid 2px blue;
    border-right: solid 2px blue;   
}

.row{
  position: relative;
}

.hex {
    min-width: var(--s);
    margin: var(--m);
    height: calc(var(--s) * 1.1547);
    display: inline-block;
    font-size: initial; /* we reset the font-size if we want to add some content */
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    background: white;   
    margin-bottom: calc(var(--m) - var(--s) * 0.2886); /* some negative margin to create overlap */
}

